import SupplierPage from 'components/pages/supplier/SupplierPage';
import { graphql } from 'gatsby';
import * as React from 'react';
import Seo from 'components/Seo';

const Index = () => (
	<div>
		<Seo
			title='Turn invoices into cash | FINTEQ'
			keywords='payout the invoice, risk-free, shorten payment terms, money without formalities, financing for companies, fast cash payout'
			description='Instantly payout invoices before the due date and decide for yourself when and how much you want to payout.'
			lang='en-US'
			locale='en_US'
		/>

		<SupplierPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
